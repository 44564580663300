<template>
  <div>
    <!-- <h1>Hello Help &amp; Support</h1> -->
    <!-- <div id="live-chat-widget-script" ></div> -->
   <div>
    </div>
    <h3 class="pl-2 pt-2 pr-2">FAQ's</h3>
    <!-- types -->
    <app-collapse
      class="pl-2 pr-2"
      accordion
      :type="collapseType"
      v-for="(item, index) in helpfaq"
      :key="index"
    >
      <app-collapse-item :title="item.PageFaq">
        <div v-html="item.PageFaqAns"></div>
      </app-collapse-item>
    </app-collapse>
    <b-row>
      <b-col class="text-center mt-2">
        <a href="/" >Back to Home</a>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import { loadScript } from "@/libs/helpers";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import { BFormRadioGroup, BFormRadio, BFormGroup, BRow, BCol } from "bootstrap-vue";

export default {
   metaInfo: {
    title: 'Help & Support | Revivify',
    htmlAttrs: {
      lang: 'en-US'
    },
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
    BRow,
    BCol
  },
  data() {
    return {
      interval: null,
      collapseType: "margin",

      helpfaq: [
        {
          id: 1,
          PageFaq: "What items can I sell?",
          PageFaqAns:
            "We welcome clothing, accessories, bags and jewellery from our <a>brand list</a> in a resalable <a>condition</a>.",
        },
        {
          id: 2,
          PageFaq: "How do you reach a valuation?",
          PageFaqAns:
            "We closely examine global market data to ascertain the price at which the same or similar items are being sold. Our unique expertise allows us to price competitively, taking into consideration the brand, popularity, condition and more.",
        },
        {
          id: 3,
          PageFaq: "What if I do not agree with the valuation?",
          PageFaqAns:
            "No problem! We only collect the item once a valuation has been agreed.",
        },
        {
          id: 4,
          PageFaq: "How should I photograph my item for approval?",
          PageFaqAns:
            "We ask that you take images of the front and back of your item, including any wear and tear or damage. It is important to highlight aspects such as loose threads and missing embellishments. We also recommend choosing a spot with plenty of natural sunlight and, if possible, positioning it against a solid colour background.",
        },
        {
          id: 5,
          PageFaq: "What is included as part of our white glove service?",
          PageFaqAns:
            "We take care of the entire sales process from end to end, including the valuation, photography, listing and delivery of your item. Our friendly and knowledgeable team are always on hand to answer any questions you might have, with an emphasis on making the process as seamless as possible for our buyers and sellers alike. This includes offering a free collection service within India, UK, EU, USA and UAE.",
        },
        {
          id: 6,
          PageFaq: "When will I receive my payment?",
          PageFaqAns:
            "Once your item has been purchased, you will be able to create your one-click invoice within your seller dashboard and receive payment almost instantly – without fuss or back and forth communication.",
        },
        {
          id: 7,
          PageFaq: "I submitted a listing, why haven’t I received an offer? ",
          PageFaqAns:
            "The first step is to check your seller dashboard. If there is no change or notification of an offer within 4 business days, please get in touch with the Revivify team.",
        },
        {
          id: 8,
          PageFaq: "How can I contact Revivify? ",
          PageFaqAns:
            "You can contact our friendly team through your seller dashboard or by sending an email to wecare@revivify.com",
        },
        {
          id: 9,
          PageFaq: "How do I price my item?",
          PageFaqAns: "We understand that for first-time sellers, pricing can feel like a minefield. If you’re interested in the current, pre-loved market price for your piece, then you are welcome to email us at seller@revivify.com. Please use the subject line “Pricing” to help us attend to your enquiry as quick as possible. Once uploaded to your seller account, along with your own clear imagery, our team will get in touch within 24 hours to confirm pricing. If you are happy, delivery will then be arranged with our trusted courier, ready for authentication and professional photography at our studio.",
        },
      ],
    };
  },
  methods: {
    loadChat() {
      this.interval = setInterval(() => {
        if (window.MessageBirdChatWidget) {
          window.MessageBirdChatWidget.show();
          clearInterval(this.interval);
        }
      }, 500);
    },

    removeTags(str) {
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    },
    removeChat() {
      window.MessageBirdChatWidget.hide();
    },
  },
  destroyed() {
    //this.removeChat();
  },
  beforeMount() {
    this.loadChat();
  },
};
</script>



<style lang="css" scoped>
.card {
  border-radius: 5px !important;
}
</style>